<template>
  <div class="mt-1">
    <h1 class="color-secondary ">Información de la cuenta</h1>
    <div class="flex align-center">
      <img class="round mr-1" src="https://via.placeholder.com/100x100?text=adomicilio1" alt="">
      <p class="color-40"><u>Cambiar imagen de perfil</u></p>
    </div>
    <div class="div grid-columns-2">
      <div class="item mt-2">
        <p class="color-90 mb-0">Nombre</p>
        <label class="icon-left color-90">
          <input type="text" value="Matzerath" placeholder="Ingresa tu Nombre">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 6C7.6575 6 9 4.6575 9 3C9 1.3425 7.6575 0 6 0C4.3425 0 3 1.3425 3 3C3 4.6575 4.3425 6 6 6ZM6 7.5C3.9975 7.5 0 8.505 0 10.5V11.25C0 11.6625 0.3375 12 0.75 12H11.25C11.6625 12 12 11.6625 12 11.25V10.5C12 8.505 8.0025 7.5 6 7.5Z" fill="#12529F"/>
          </svg>
        </label>
      </div>
      <div class="item mt-2">
        <p class="color-90 mb-0">Apellido</p>
        <label class="icon-left color-90">
          <input type="text" value="Chávez Narváez" placeholder="Ingresa tu Apellido">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 6C7.6575 6 9 4.6575 9 3C9 1.3425 7.6575 0 6 0C4.3425 0 3 1.3425 3 3C3 4.6575 4.3425 6 6 6ZM6 7.5C3.9975 7.5 0 8.505 0 10.5V11.25C0 11.6625 0.3375 12 0.75 12H11.25C11.6625 12 12 11.6625 12 11.25V10.5C12 8.505 8.0025 7.5 6 7.5Z" fill="#12529F"/>
          </svg>
        </label>
      </div>
      <div class="item">
        <p class="color-90 mb-0">Apellido</p>
        <label class="icon-left color-90">
          <input type="email" value="z@pekxel.studio" placeholder="Ingresa tu Email">
          <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.1667 0.333984H1.83332C1.05416 0.333984 0.42374 0.971484 0.42374 1.75065L0.416656 10.2507C0.416656 11.0298 1.05416 11.6673 1.83332 11.6673H13.1667C13.9458 11.6673 14.5833 11.0298 14.5833 10.2507V1.75065C14.5833 0.971484 13.9458 0.333984 13.1667 0.333984ZM12.8833 3.3444L7.87541 6.47523C7.64874 6.6169 7.35124 6.6169 7.12457 6.47523L2.11666 3.3444C1.93957 3.23107 1.83332 3.03982 1.83332 2.8344C1.83332 2.35982 2.35041 2.07648 2.75416 2.3244L7.49999 5.29232L12.2458 2.3244C12.6496 2.07648 13.1667 2.35982 13.1667 2.8344C13.1667 3.03982 13.0604 3.23107 12.8833 3.3444Z" fill="#12529F"/>
          </svg>
        </label>
      </div>
      <div class="item">
        <p class="color-90 mb-0">Teléfono</p>
        <label class="icon-left color-90">
          <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.87503 4.12461H7.12503V5.37461H5.87503V4.12461ZM8.37503 4.12461H9.62503V5.37461H8.37503V4.12461ZM10.875 4.12461H12.125V5.37461H10.875V4.12461ZM11.0188 8.03711L9.43128 7.85586C9.05003 7.81211 8.67503 7.94336 8.40628 8.21211L7.25628 9.36211C5.48753 8.46211 4.03753 7.01836 3.13753 5.24336L4.29378 4.08711C4.56253 3.81836 4.69378 3.44336 4.65003 3.06211L4.46878 1.48711C4.39378 0.85586 3.86253 0.380859 3.22503 0.380859H2.14378C1.43753 0.380859 0.850025 0.968359 0.893775 1.67461C1.22503 7.01211 5.49378 11.2746 10.825 11.6059C11.5313 11.6496 12.1188 11.0621 12.1188 10.3559V9.27461C12.125 8.64336 11.65 8.11211 11.0188 8.03711Z" fill="#747474"/>
          </svg>
          <input type="text" value="2227393497" placeholder="Ingresa tu Teléfono">
        </label>
      </div>
      <div class="item">
        <button>Actualizar Datos</button>
        <button class="btn-clean ml-2 color-40"><u>Cancelar</u></button>
      </div>
    </div>
    <div class="div mt-4 mb-5">
      <h2 class="h1 color-secondary ">Programa de referidos</h2>
      <div class="grid-columns-2">
        <div class="item">
          <p class="alt">Copia este código y compártelo con tus amigos y disfruten de los beneficios.</p>
          <label class="code icon-left color-90">
            <input type="text" value="CODIGO123" disabled>
            <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.94 3.94065L7.71999 0.720651C7.47332 0.473984 7.13332 0.333984 6.77999 0.333984H1.99999C1.26666 0.333984 0.666656 0.933984 0.666656 1.66732V12.334C0.666656 13.0673 1.26666 13.6673 1.99999 13.6673H9.99999C10.7333 13.6673 11.3333 13.0673 11.3333 12.334V4.88732C11.3333 4.53398 11.1933 4.19398 10.94 3.94065ZM7.33332 7.00065C7.69999 7.00065 7.99999 7.30065 7.99999 7.66732V9.66732C7.99999 10.034 7.69999 10.334 7.33332 10.334H6.66666C6.66666 10.7007 6.36666 11.0007 5.99999 11.0007C5.63332 11.0007 5.33332 10.7007 5.33332 10.334H4.66666C4.29999 10.334 3.99999 10.034 3.99999 9.66732C3.99999 9.30065 4.29999 9.00065 4.66666 9.00065H6.66666V8.33398H4.66666C4.29999 8.33398 3.99999 8.03398 3.99999 7.66732V5.66732C3.99999 5.30065 4.29999 5.00065 4.66666 5.00065H5.33332C5.33332 4.63398 5.63332 4.33398 5.99999 4.33398C6.36666 4.33398 6.66666 4.63398 6.66666 5.00065H7.33332C7.69999 5.00065 7.99999 5.30065 7.99999 5.66732C7.99999 6.03398 7.69999 6.33398 7.33332 6.33398H5.33332V7.00065H7.33332Z" fill="#12529F"/>
            </svg>
            <button class="btn-secondary btn-alt">Copiar código</button>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
input {
  width: 100%;
}
.code {
  button {
    position: absolute;
    right: 1em;
    top: 1em;
  }
}
</style>
